
export function createNode(content) {
  const tempNode = document.createElement('div')

  tempNode.innerHTML = content

  return tempNode.children[0]
}

export function removeAll(selector) {
  removeAllNodes(document.querySelectorAll(selector))
}

export function removeAllNodes(nodes) {
  nodes.forEach((node) => {
    removeNode(node)
  })
}

export function removeNode(node) {
  node.parentNode?.removeChild(node)
}

export function readdNode(node) {
  const parentNode = node.parentNode
  parentNode.removeChild(node)
  parentNode.appendChild(node)
}

export function submitForm(form, options = {}) {
  form.dispatchEvent(new CustomEvent("submit", { bubbles: true, cancelable: true, ...options }))

  if (form.getAttribute("data-remote") !== "true") form.submit()
}

export function outerHtml(selector, markup) {
  document.querySelectorAll(selector).forEach((node) => {
    node.outerHTML = markup
  })
}

export function innerHtml(selector, markup) {
  document.querySelectorAll(selector).forEach((node) => {
    node.innerHTML = markup
  })
}

export function toggleClass(el, className, bool) {
  bool ? el.classList.add(className) : el.classList.remove(className)
}

export function createDelegator(selector, listener) {
  return function(e) {
    e.delegateTarget = e.target.closest(selector)

    if (e.delegateTarget) listener.call(this, e)
  }
}

export async function animate(node, className) {
  const promise = new Promise((resolve) => {
    const listener = () => {
      node.removeEventListener("animationend", listener)
      node.classList.remove(className)

      resolve()
    }

    node.addEventListener("animationend", listener)
  })

  node.classList.add(className)

  return promise
}

export async function animateToggle(node, className, animationName1, animationName2) {
  if (node.classList.contains(className)) {
    node.classList.remove(className)
    await animate(node, animationName2)
  } else {
    node.classList.add(className)
    await animate(node, animationName1)
  }
}

export function scrollIntoView(element) {
  if ("scrollBehavior" in document.documentElement.style) {
    element.scrollIntoView({ block: "center", behavior: "smooth" })
  } else {
    element.scrollIntoView()
  }
}
