import { createDelegator, removeAll, createNode } from "./domHelpers"
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"

export default function Overlay(node) {
  disableBodyScroll(node)

  node.addEventListener("click", createDelegator(".overlay-close", (e) => {
    e.preventDefault()

    hideOverlay()
  }))

  const onClickAway = (e) => {
    if (!e.target.closest(".overlay-inner")) hideOverlay()
  }

  const onKeydown = (e) => {
    if (e.which === 27) hideOverlay()
  }

  document.body.addEventListener("click", onClickAway)
  document.body.addEventListener("touchstart", onClickAway)
  window.addEventListener("keydown", onKeydown)

  return () => {
    document.body.removeEventListener("click", onClickAway)
    document.body.removeEventListener("touchstart", onClickAway)
    window.removeEventListener("keydown", onKeydown)
  }
}

export function showOverlay(markup) {
  hideOverlay()

  document.body.appendChild(createNode(markup))
}

export function hideOverlay() {
  removeAll(".overlay")

  clearAllBodyScrollLocks()
}
