import { removeAll, createDelegator, createNode } from "./domHelpers"

export default function CancelZoom(node) {
  const onFocus = createDelegator(`input[type="text"], textarea, select`, () => {
    removeAll(`meta[name="viewport"]`)
    document.querySelector("head").appendChild(createNode(`<meta name="viewport" content="initial-scale=1.0, width=device-width, user-scalable=0" />`))
  })

  const onBlur = createDelegator(`input[type="text"], textarea, select`, () => {
    removeAll(`meta[name="viewport"]`)
    document.querySelector("head").appendChild(createNode(`<meta name="viewport" content="initial-scale=1.0, width=device-width, user-scalable=1" />`))
  })

  node.addEventListener("focus", onFocus, true)
  node.addEventListener("blur", onBlur, true)

  return () => {
    node.removeEventListener("focus", onFocus)
    node.removeEventListener("blur", onBlur)
  }
}
