function isPromise (value) {
  return value && typeof value.subscribe !== "function" && typeof value.then === "function"
}

function mountNodes(nodes, Component) {
  if (isPromise(Component)) {
    Component.then((module) => mountNodes(nodes, module.default))

    return
  }

  for (let i = 0; i < nodes.length; i++) {
    Component(nodes[i])
  }
}

export default function mount(className, ComponentFn) {
  document.addEventListener("DOMContentLoaded", () => {
    const nodes = document.getElementsByClassName(className)
    if (nodes.length > 0) mountNodes(nodes, ComponentFn())
  })
}
