export default {
  de: {
    errors: {
      default: 'Ein Fehler ist aufgetreten',
    },
  },
  en: {
    errors: {
      default: 'An error has occurred',
    },
  },
}
