import translations from "./translations"
import { showStatusmessage } from "./Statusmessage"
import { getLanguage } from "./i18n"

export default function RailsErrorHandler(node) {
  const language = getLanguage()

  const onError = () => {
    showStatusmessage(translations[language].errors.default, "error")
  }

  node.addEventListener("ajax:error", onError)

  return () => {
    node.removeEventListener("ajax:error", onError)
  }
}
