import "core-js/stable"
import "regenerator-runtime/runtime"
import "element-closest-polyfill"
import "nodelist-foreach-polyfill"
import "element-matches-polyfill"
import "whatwg-fetch"

import { removeAll, outerHtml, innerHtml } from "../domHelpers"
import automount from "../automount"
import mount from "../mount"
import Rails from "@rails/ujs"
import RailsErrorHandler from "../RailsErrorHandler"
import TrackRemote from "../TrackRemote"
import Preloading, { startPreloading, stopPreloading } from "../Preloading"
import Statusmessage, { showStatusmessage, hideStatusmessage } from "../Statusmessage"
import Overlay, { showOverlay, hideOverlay } from "../Overlay"
import CancelZoom from "../CancelZoom"
import LazyImage from "../LazyImage"
import Header from "../Header"

window.helpers = {
  startPreloading,
  stopPreloading,
  showStatusmessage,
  hideStatusmessage,
  showOverlay,
  hideOverlay,
  removeAll,
  outerHtml,
  innerHtml,
}

Rails.start()

mount("rails-error-handler", () => RailsErrorHandler)
mount("track-remote", () => TrackRemote)
mount("cancel-zoom", () => CancelZoom)
mount("header", () => Header)
mount("lookbook", () => import(/* webpackChunkName: "Lookbook" */ "../Lookbook"))
mount("product-details", () => import(/* webpackChunkName: "ProductDetails" */ "../ProductDetails"))
mount("checkout-form", () => import(/* webpackChunkName: "CheckoutForm" */ "../CheckoutForm"))

automount("preloading", () => Preloading)
automount("statusmessage", () => Statusmessage)
automount("lazy-image", () => LazyImage)
automount("responsively-lazy", () => LazyImage)
automount("overlay", () => Overlay)
automount("select-remote", () => import(/* webpackChunkName: "SelectRemote" */ "../SelectRemote"))
automount("poller", () => import(/* webpackChunkName: "Poller" */ "../Poller"))
automount("custom-remote", () => import(/* webpackChunkName: "CustomRemote" */ "../CustomRemote"))
automount("popup-menu", () => import(/* webpackChunkName: "PopupMenu" */ "../PopupMenu"))
automount("slider", () => import(/* webpackChunkName: "Slider" */ "../Slider"))
automount("carousel", () => import(/* webpackChunkName: "Carousel" */ "../Carousel"))
automount("autosubmit", () => import(/* webpackChunkName: "Autosubmit" */ "../Autosubmit"))
automount("stripe-checkout", () => import(/* webpackChunkName: "StripeCheckout" */ "../StripeCheckout"))
