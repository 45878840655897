import { createNode, removeAll, createDelegator } from './domHelpers'

export default function Preloading(node) {
  const onKeydown = (e) => {
    if (e.which === 27) stopPreloading()
  }

  const onPageshow = () => {
    stopPreloading()
  }

  node.addEventListener("ajax:beforeSend", createDelegator("form", (e) => {
    const [xhr] = e.detail

    xhr.addEventListener("loadend", () => {
      stopPreloading()
    })
  }))

  node.addEventListener("click", createDelegator(`a:not([target]), input[type="button"], button`, (e) => {
    if (e.altKey || e.ctrlKey || e.metaKey || e.shiftKey) return

    startPreloading()
  }))

  node.addEventListener("submit", createDelegator("form", () => {
    startPreloading()
  }))

  window.addEventListener("keydown", onKeydown)
  window.addEventListener("pageshow", onPageshow)

  return () => {
    window.removeEventListener("keydown", onKeydown)
    window.removeEventListener("pageshow", onPageshow)
  }
}

export function startPreloading() {
  stopPreloading()

  document.body.appendChild(createNode(`
    <div class="preloading-indicator">
      <div class="preloading-indicator-image"></div>
    </div>
  `))
}

export function stopPreloading() {
  removeAll(".preloading-indicator")
}
