function isPromise (value) {
  return value && typeof value.subscribe !== "function" && typeof value.then === "function"
}

const mounts = {}

function mount(node, mountpoint, Component) {
  if (isPromise(Component)) {
    Component.then((module) => mount(node, mountpoint, module.default))

    return
  }

  node._automounts = node._automounts || {}

  if (node._automounts[mountpoint]) return

  const unmountFn = Component(node)
  node._automounts[mountpoint] = typeof unmountFn === "function" ? unmountFn : (() => {})
}

function mountWithin(baseNode) {
  for (let className in mounts) {
    if (baseNode.classList && baseNode.classList.contains(className)) {
      mount(baseNode, className, mounts[className]())
    }

    const nodes = baseNode.getElementsByClassName(className)

    for (let i = 0; i < nodes.length; i++) {
      mount(nodes[i], className, mounts[className]())
    }
  }
}

function unmount(node) {
  for (let key in node._automounts) {
    node._automounts[key]()
  }

  node._automounts = {}
}

function unmountWithin(baseNode) {
  for (let className in mounts) {
    if (baseNode.classList && baseNode.classList.contains(className)) {
      unmount(baseNode)
    } else {
      const nodes = baseNode.getElementsByClassName(className)

      for (let i = 0; i < nodes.length; i++) {
        unmount(nodes[i])
      }
    }
  }
}

const observer = new MutationObserver((mutations) => {
  mutations.forEach((mutation) => {
    const addedNodes = mutation.addedNodes

    for (let i = 0; i < addedNodes.length; i++) {
      if (addedNodes[i].nodeType === 1) {
        mountWithin(addedNodes[i])
      }
    }

    const removedNodes = mutation.removedNodes

    for (let u = 0; u < removedNodes.length; u++) {
      if (removedNodes[u].nodeType === 1) {
        unmountWithin(removedNodes[u])
      }
    }
  })
})

document.addEventListener("DOMContentLoaded", () => {
  mountWithin(document.documentElement)

  observer.observe(document.documentElement, { childList: true, subtree: true })
})

export default function(mountpoint, fn) {
  mounts[mountpoint] = fn
}
