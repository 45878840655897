import { createDelegator, animateToggle } from "./domHelpers"

export default function Header(node) {
  node.addEventListener("click", createDelegator(".header-categories-toggle", (e) => {
    e.preventDefault()

    const headerCategories = node.querySelector(".header-categories")
    if (!headerCategories) return

    animateToggle(headerCategories, "is-visible", "is-expanding", "is-collapsing")
  }))
}
