let intersectionObserver

export default function LazyImage(node) {
  if (!window.IntersectionObserver) {
    node.setAttribute("srcset", node.getAttribute("data-srcset"))
    return
  }

  if (!intersectionObserver) {
    intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) return

        entry.target.setAttribute("srcset", entry.target.getAttribute("data-srcset"))

        intersectionObserver.unobserve(entry.target)
      })
    })
  }

  intersectionObserver.observe(node)

  return () => {
    intersectionObserver.unobserve(node)
  }
}

export function resetObserver() {
  intersectionObserver?.disconnect()
  intersectionObserver = null
}
