import { createNode, removeNode, animateToggle, animate } from "./domHelpers"
import sleep from "./sleep"

export default async function Statusmessage(node) {
  await animateToggle(node, "is-visible", "is-showing", "is-hiding")

  const content = node.querySelector(".statusmessage-content")
  if (content) animate(content, "is-blinking")

  await sleep(2000)
  await animateToggle(node, "is-visible", "is-showing", "is-hiding")

  node.parentNode.removeChild(node)
}

export function showStatusmessage(message, kind = "notice") {
  const node = createNode(`
    <div class="statusmessage statusmessage-${kind}">
      <p class="statusmessage-content">${message}</p>
    </div>
  `)

  document.body.appendChild(node)
}

export async function hideStatusmessage() {
  return await Promise.all(
    Array.from(document.querySelectorAll(".statusmessage")).map(async (node) => {
      await animateToggle(node, "is-visible", "is-showing", "is-hiding")
      node.parentNode.removeChild(node)
    })
  )
}
