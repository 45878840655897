import { createDelegator } from "./domHelpers"

export default function TrackRemote(node) {
  const onAjaxBeforeSend = createDelegator("[data-remote=true]", (e) => {
    const method = e.delegateTarget.getAttribute("data-method") || "GET"

    if (method.toUpperCase() === "GET") window._gaq?.push(["_trackPageview", e.detail[1].url])
  })

  node.addEventListener("ajax:beforeSend", onAjaxBeforeSend)

  return () => {
    node.removeEventListener("ajax:beforeSend", onAjaxBeforeSend)
  }
}
